import React, { FunctionComponent, useState, useEffect, useRef, FormEvent, useContext } from "react";
import { PortableText, MultiLineHeadline } from "../../types/SanityTypes";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { Modal, Col, Row } from "react-bootstrap";
import SurveyBlock from "../SurveyBlock";
import "./styles.scss";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContext } from "../../services/LocalizedContextService";

export type ModalBlockInterface = {
  data: {
    name: string;
    headline: MultiLineHeadline;
    _rawIntroduction: PortableText;
    surveyBlock?: {
      beyondSport: boolean;
      submitCTA: string;
      questions: {
        question: string;
        questionType: string;
      }[];
    };
  };
  target?: string;
  showModal: boolean;
  handleClose?: () => void;
};

const ModalBlock: FunctionComponent<ModalBlockInterface> = ({ data, target, showModal, handleClose }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues: Record<string, unknown> = {};
  const { sanityLabels, language } = useContext(LocalizedContext);
  const { errorMessages, sliderLabels } = sanityLabels || {};
  const { siteUrl, locale } = useSiteMetadata(language);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localeLanguage, country] = locale.split("_");

  useEffect(() => {
    data.surveyBlock?.questions.forEach((item, index) => {
      const questionNumber = `Q${index + 1}`;
      initialValues[`${questionNumber}-text`] = item.question;
      if (item.questionType === "text") {
        initialValues[`${questionNumber}-answer`] = "";
      }
      if (item.questionType === "yesNo") {
        initialValues[`${questionNumber}-answer`] = sliderLabels?.yes;
      }
      if (item.questionType === "slider") {
        initialValues[`${questionNumber}-answer`] = "3";
      }
    });
  }, [data.surveyBlock?.questions, sliderLabels?.yes, initialValues]);

  const [values, setValues] = useState(initialValues || {});
  const [ctaSurveyCompleted, setCtaSurveyCompleted] = useState(false);
  const [error, setError] = useState<Error | null>();

  const formRendered = useRef(true);
  const newToday = new Date();
  const newTime = `${newToday.getHours()}:${newToday.getMinutes()}`;
  const newDate = `${newToday.getFullYear()}/${newToday.getMonth() + 1}/${newToday.getDate()}`;

  useEffect(() => {
    if (formRendered.current) {
      setValues(initialValues);
    }
    formRendered.current = false;
  }, [initialValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { name, value } = target;
    event.persist();
    setValues({ ...values, [`${name}-answer`]: value });
  };

  const handleSubmit = async (event: FormEvent) => {
    if (event) event.preventDefault();
    setCtaSurveyCompleted(true);
    setError(null);

    try {
      await fetch(siteUrl + "/.netlify/functions/submit", {
        method: "post",
        headers: ({ Accept: "*/*", "Content-Type": "application/json" } as unknown) as Headers,
        body: JSON.stringify({
          ...values,
          beyondSportSurvey: data.surveyBlock?.beyondSport,
          targetID: target,
          country: country,
          language: localeLanguage,
          surveyName: data.name,
          Date: newDate,
          Time: newTime
        })
      });
    } catch (e) {
      setError(e as any);
    }
  };

  return (
    <>
      <Modal
        centered
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        data-testid="modal"
      >
        <div className="modal-block">
          {!ctaSurveyCompleted ? (
            <>
              <Modal.Header closeButton>
                <Modal.Title data-testid="modal-title">
                  {data.headline && (
                    <h3>
                      <span className="heading-line-1">{data.headline.primaryText}</span>
                      {data.headline.secondaryText && (
                        <span className="heading-line-2">{data.headline.secondaryText}</span>
                      )}
                      {data.headline.tertiaryText && (
                        <span className="heading-line-1">{data.headline.tertiaryText}</span>
                      )}
                    </h3>
                  )}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body data-testid="modal-body">
                <Row>
                  <Col xs={12} md={{ span: 8, offset: 2 }}>
                    {data._rawIntroduction && (
                      <div className="body-copy" id="modallockCopy" style={{ paddingBottom: "1rem" }}>
                        <BlockContent blocks={data._rawIntroduction} />
                      </div>
                    )}
                    {data.surveyBlock && (
                      <SurveyBlock
                        data={data.surveyBlock}
                        target={target}
                        values={values}
                        submit={handleSubmit}
                        handleChange={handleChange}
                      />
                    )}
                  </Col>
                </Row>
              </Modal.Body>
            </>
          ) : error !== null && error !== undefined ? (
            <>
              <Modal.Header closeButton />
              <Modal.Body className="try-again">
                <p aria-live="assertive">{errorMessages?.generalError}</p>
              </Modal.Body>
            </>
          ) : (
            <>
              <Modal.Header closeButton />
              <Modal.Body className="thanks">
                <h3>{sliderLabels?.completion || "Thank you for completing our survey"}</h3>
              </Modal.Body>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalBlock;
